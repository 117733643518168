
// 自定义指令——实现动态变化的数字
const digit = {
    inserted(el, binding) {
        let hasEntered = false
        let observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting && !hasEntered) {
                    // 元素进入视图
                    // 获取到需要动态变化的数值
                    // let finalNum = el.innerText
                    let finalNum = binding.value
                    // 动画间隔
                    let rate = 30
                    // 总时长
                    let time = 2000
                    // 步长，数据增加间隔
                    let step = finalNum / (time / rate)
                    // 初始值
                    let count = 0
                    let timer = setInterval(() => {
                        if (count > finalNum) {
                            count = finalNum
                            el.innerText = count
                            clearInterval(timer)
                            timer = null
                        } else {
                            count = count + step
                            el.innerText = count.toFixed(0)
                        }
                    }, rate)
                    hasEntered = true
                    observer.unobserve(el)

                } else if (!entry.isIntersecting && hasEntered) {
                    // 元素离开视图
                    // hasEntered = false
                }
            })
        }, binding.value.IntersectionObserver || {})
        observer.observe(el)
    }
}

const titleAnimate = {
    inserted(el, binding) {
        binding.addClass = () => {
            const h = document.documentElement.clientHeight || document.body.clientHeight
            const { top } = el.getBoundingClientRect()
            if (top < h) {
                // 元素刚露头
                if (el.className.indexOf(binding.value) == -1) {
                    // 初次还未绑定动画类名，则新增类名
                    el.className = binding.value + ' ' + el.className
                }
                if (binding.addClass) {
                    window.removeEventListener('scroll', binding.addClass)
                }
            }
        }
        window.addEventListener('scroll', binding.addClass, true)
        binding.addClass()
    },
    unbind(el, binding) {
        if (binding.addClass) {
            window.removeEventListener('scroll', binding.addClass)
        }
    }
}

export default { digit, titleAnimate }