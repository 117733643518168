import cloudbase from "@cloudbase/js-sdk";

async function getCloudBaseApp() {
    const app = cloudbase.init({
        env: 'cms-6ghze6jgb6d413ae'
    });

    // 登录
    const auth = app.auth({ persistence: "local" });
    const loginState = await auth.getLoginState();

    // 匿名登录
    if (!loginState) {
        await auth.anonymousAuthProvider().signIn();
    }

    return app;
}

// 获取条件数据：where筛选出对应条件的数据
export async function getData(collectionName, condition, limit) {
    const app = await getCloudBaseApp();
    const db = app.database();
    const { data } = await db.collection(collectionName).where(condition).limit(limit).get();
    return data
}

// 获取数据：返回指定字段
export async function seleData(collectionName, condition, limit) {
    const app = await getCloudBaseApp();
    const db = app.database();
    const { data } = await db.collection(collectionName).field(condition).limit(limit).get();
    return data
}

// where和field同时用
export async function getSeleData(collectionName, field, condition, limit) {
    const app = await getCloudBaseApp();
    const db = app.database();
    const { data } = await db.collection(collectionName).field(field).where(condition).limit(limit).get();
    return data
}

// 获取全部信息
export async function getAllData(collectionName, limit) {
    const app = await getCloudBaseApp();
    const db = app.database();
    const { data } = await db.collection(collectionName).limit(limit).get();
    return data
}

// 新增数据
export async function addData(collectionName, addCondition) {
    const app = await getCloudBaseApp();
    const db = app.database();
    await db.collection(collectionName)
        .add(addCondition)
        .catch((e) => {
            console.log(e);
        });
}
