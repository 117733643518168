import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js'

// 组件库引入
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import { Popover, Select, Input, Button, Carousel } from 'element-ui'
Vue.use(ElementUI)
Vue.use(Popover)
Vue.use(Select)
Vue.use(Input)
Vue.use(Button)
Vue.use(Carousel)

// 自定义指令
import directive from './utils/change'

Vue.config.productionTip = false


import cloudbase from '@cloudbase/js-sdk';

// // 初始化 SDK
const app = cloudbase.init({
  env: 'cms-6ghze6jgb6d413ae' // 填入您的环境 ID
});
Vue.prototype.getFileURL = async function (argument) {
  let result = []
  await app.getTempFileURL({ fileList: argument }).then((res) => {
    res.fileList.forEach((el) => {
      if (el.code === "SUCCESS") {
        result.push(el.tempFileURL)
      } else {
        // alert('获取资源链接失败')
      }
    });
  })
  return result
}

Vue.prototype.runConcurrently = async function (array) {
  await Promise.all(array)
},

  // 注册名为change的指令
  Vue.directive('change', directive.digit)
Vue.directive('up', directive.titleAnimate)

new Vue({
  router,
  render: h => h(App),
  store,
  mounted() {
    this.$nextTick(() => {
      document.dispatchEvent(new Event('render-event'))
    })
  }
}).$mount('#app')
